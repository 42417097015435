<template>
  <b-container class="import-wizard-step import-wizard-step01">
    <b-row no-gutters>

      <b-col cols="4" class="sidebar-text text-left py-5 pl-2 pr-5" align-self="start">
        <h2 class="text-dark">{{ $t(translationPath + 'intro_title') }}</h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>

      <b-col cols="8" class="detail-form text-left py-5 px-5" align-self="center">

        <b-form-group
          label-class="pr-4 text-black"
          label-for="file"
          label-cols-lg="3"
          label-align-lg="left"
          required
          class="pt-4"
          :invalid-feedback="invalidFileFeedback"
        >

          <template slot="label">{{ $t(translationPath + 'file_label') }}</template>
          <template slot="description">{{ $t(translationPath + 'file_description') }}</template>
          <b-form-file id="file"
            class="form-control"
            :placeholder="$t(translationPath + 'file_placeholder')"
            :drop-placeholder="$t(translationPath + 'file_drop_placeholder')"
            :state="$v.file.$dirty ? !$v.file.$error : null"
            v-model="file"
            @input="$v.file.$touch()"
          ></b-form-file>
        </b-form-group>

        <b-table ref="importTable" class="spirecta-simple-table accounts-table spirecta-table-where-row-is-selected" show-empty hover responsive striped
          :items="accounts"
          :fields="fieldValues"
          :busy="busyState"
          selectable
          select-mode="single"
          @row-selected="rowSelected"
          stacked="md"
          :emptyText="$t(translationPath + 'no_data')"
        >
          <!--loader-->
          <template v-slot:table-busy>
            <loader/>
          </template>
          <!--loader ends here-->
          <template v-slot:head(importable_type)="">

          </template>
          <template v-slot:cell(title)="data">
            {{ currentCOA.prefix_account_title_with_code ? data.item.title_with_code : data.item.title }}
          </template>
          <template v-slot:cell(importable_type)="data">
            <b-link class="text-gray"> {{data.value}} </b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'

const isFileOk = getter => function () {
  return this.isFileOk
}
const isFileTypeOk = getter => function () {
  return this.isFileTypeOk
}
const isFileStructOk = getter => function () {
  return this.isFileStructOk
}

export default {
  name: 'ImportAccountStatementSpreadsheetWizardStep01',
  validations: {
    file: {
      required,
      fileOk: isFileOk(),
      fileTypeOk: isFileTypeOk(),
      fileStructOk: isFileStructOk()
    },
    account: {
      required
    },
    form: ['file', 'account']
  },
  components: { Loader },
  data () {
    return {
      translationPath: 'transactions.import.import-spreadsheet-wizard.step1.',
      file: null,
      isFileOk: true,
      isFileTypeOk: true,
      isFileStructOk: true,
      account: null,
      accounts: null,
      busyState: true
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      return [
        { label: this.$t(this.translationPath + 'table_head'), key: 'title', sortable: true },
        { label: '', key: 'importable_type', sortable: false, formatter: (value) => { return this.$t('common.' + value) } }
      ]
    },
    invalidFileFeedback () {
      if (this.$v.file.required === false) return this.$t(this.translationPath + 'errors.file_required')
      if (this.$v.file.fileTypeOk === false) return this.$t(this.translationPath + 'errors.file_invalid_type')
      if (this.$v.file.fileStructOk === false) return this.$t(this.translationPath + 'errors.file_invalid_struct')
      if (this.$v.file.fileOk === false) return this.$t(this.translationPath + 'errors.file_unknown_error')
      return ''
    },
    helpLink () {
      switch (this.$i18n.locale) {
        case 'sv':
          return '5e32e29104286364bc94a3b0'
        case 'da':
          return '5dd7959304286364bc921113'
        default:
          return '5dd792b604286364bc9210fd'
      }
    }
  },
  methods: {
    async validate () {
      this.$v.form.$touch()
      let isValid = !this.$v.form.$invalid

      if (isValid) {
        const formData = new FormData()
        formData.append('file', this.file)
        const config = {
          baseURL: process.env.VUE_APP_ROOT_API
        }
        const UploadFileInstance = axios.create(config)
        UploadFileInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data'
        await UploadFileInstance.post('/transactions/import-spreadsheet/upload', formData)
          .then((response) => {
            const responseModel = {
              fileName: response.data.data.fileName,
              columnHeaders: response.data.data.columnHeaders,
              // defaultOffsetAccount: this.account,
              offset_account: this.account
            }
            this.$emit('validate-success', responseModel)
          })
          .catch((error) => {
            console.error(error)
            switch (error.response.data.error.code) {
              case 1:
                this.isFileTypeOk = false
                break
              case 2:
                this.isFileStructOk = false
                break
              default:
                this.isFileOk = false
                break
            }
            isValid = false
          })
      } else {
        if (!this.account) {
          this.$bvToast.toast(this.$t(this.translationPath + 'errors.account_required_msg'), {
            title: this.$t(this.translationPath + 'errors.account_required_title'),
            variant: 'danger',
            solid: true
          })
        }
      }

      return isValid
    },
    rowSelected (items) {
      this.account = null
      if (items.length) {
        this.account = items[0]
      }
    }
  },
  watch: {
    file () {
      this.isFileOk = true
      this.isFileTypeOk = true
      this.isFileStructOk = true
    }
  },
  async created () {
    this.busyState = true
    await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=asset,liability&only_importable=1&hide_inactive=1`)
      .then(response => {
        this.accounts = response.data.data
        this.$emit('offset-accounts-loaded', this.accounts)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.busyState = false
      })
  }
}
</script>

<style lang="scss">

@import '@/assets/scss/components/spirecta-table-where-row-is-selected.scss';
.accounts-table {
}
</style>
