<template>
  <tab-basic :title="$t(translationPath + 'title')">
    <template v-slot:heading>
      {{ $t(translationPath + 'title')}}
    </template>
    <template v-slot:description>
      {{ $t(translationPath + 'description')}}
    </template>
    <template v-slot:content>
      <split-transaction-component ref="splitTransactionComponentRef" :offsetAccountName="offsetAccountName" v-bind:currentUser="currentUser" v-bind:currentCOA="currentCOA" v-bind:transactionAmount="transactionAmount" v-bind:transactionDate="$moment(transactionDate).format('YYYY-MM-DD')" v-bind:transactionTitle="transactionTitle" v-bind:transactionType="transactionType" @disable-save="disableSaveHandler" @save="(value) => $emit('save', value)"/>
    </template>
    <template v-slot:footer>
      <b-col xs="12" class="px-0 btnAction mt-3">
        <b-button variant="primary" class="float-right" @click="emitOnSave" :disabled="disableSave">
          {{ $t("common.save") }}
        </b-button>
        <b-button variant="outline" class="float-right" @click="close">
          {{ $t("common.cancel")}}
        </b-button>
      </b-col>
    </template>
  </tab-basic>
</template>

<script>
import SplitTransactionComponent from '../component/SplitTransactionComponent'
import TabBasic from './TabBasic'

export default {
  name: 'SplitTab',
  components: {
    SplitTransactionComponent,
    TabBasic
  },
  props: {
    offsetAccountName: {
      type: String,
      default: null
    },
    currentUser: {
      type: Object,
      default: function () {
        return {}
      }
    },
    currentRecordId: {
      type: Number,
      default: null
    },
    currentCOA: {
      type: Object,
      default: function () {
        return {}
      }
    },
    transactionAmount: {
      type: Number,
      default: 0
    },
    transactionDate: {
      default: function () {
        return '20-10-2021'
      }
    },
    transactionTitle: {
      type: String,
      default: null
    },
    transactionType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'transactions.import.import_wizard_v2.tab.split.',
      disableSave: false
    }
  },
  methods: {
    disableSaveHandler (value) {
      this.disableSave = value
    },
    close () {
      this.$emit('cancel-clicked')
    },
    emitOnSave () {
      this.$refs.splitTransactionComponentRef.emitSaveEvent(this.currentRecordId)
    }
  }
}
</script>

<style lang="scss">
  .btnAction {
    display: inline-block;
    width: 100%;
  }
</style>
